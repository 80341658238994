<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="区域名称">
					<a-input size="default" v-model="region_name" placeholder="输入区域名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="status" slot-scope="text, record">
					<a-tag color="blue" v-if="record.status == 1">
						启用
					</a-tag>
					<a-tag color="orange" v-if="record.status == 0">
						隐藏
					</a-tag>
				</div>
				<div slot="do" slot-scope="text, record">
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span" @click="nodeAdmin(record)">
						管理站点设置
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.region_id)">
						<template slot="title">
							<p>是否确定删除该区域？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="form.region_id ? '编辑' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="所属客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择所属客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="区域名称" prop="region_name">
					<a-input v-model="form.region_name" placeholder="请输入区域名称" />
				</a-form-model-item>
				<a-form-model-item label="是否启用">
					<a-radio-group v-model="form.status">
						<a-radio :value="1">
							启用
						</a-radio>
						<a-radio :value="0">
							隐藏
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal v-model="nodeAdminShow" title="设置区域管理站点" width="840px" @ok="nodeAdminOk" @cancel="nodeAdminCancel">
			<a-spin :spinning="detailLoading">
				<p style="width: 95%;margin: 0 auto; margin-bottom: 20px;font-size: 16px;">当前区域名称： {{regioninfo.region_name}}
				</p>
				<div class="tes roleee">
					<div class="taes">
						<div class="contet chebox">
							<a-checkbox-group v-model="checks" :options="nodeList">
                                <span slot="label" slot-scope="{ value }">{{ value }}</span>
                            </a-checkbox-group>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '区域名称',
			dataIndex: 'region_name',
			key: 'region_name',
		},
		{
			title: '所属客户',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			scopedSlots: {
				customRender: 'status'
			},
		},
		{
			title: '操作',
			key: 'do',
			width: 250,
			scopedSlots: {
				customRender: 'do'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				corpList: [],
				form: {
					region_id: 0,
					corp_id: null,
					region_name: '',
					status: 1
				},
				rules: {
					corp_id: [{
						required: true,
						message: '请选择所属客户',
						trigger: 'blur'
					}],
					region_name: [{
						required: true,
						message: '请输入区域名称',
						trigger: 'blur'
					}],
				},

				region_name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,

				nodeList: [],
				nodeAdminShow: false,
				detailLoading: false,
				regioninfo: {},
				checks: []
			};
		},
		mounted() {
			this.getTable()
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
				})
				this.corpTreeList = corpTreeList
			})
		},
		methods: {
			handleSubmit(e) {
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.region_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.region.list/',
					data: {
						region_name: this.region_name,
						page: this.tablePage
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.region.del/',
					data: {
						region_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除区域成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.form = {
					...data
				}
				this.modelVisible = true;
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.corp_name
						http({
							method: 'post',
							url: '/api.region.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.region_id) {
									this.$message.success('编辑区域信息成功');
								} else {
									this.$message.success('添加区域成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					region_id: 0,
					corp_id: null,
					region_name: '',
					status: 1
				}
				this.formKey = Math.random()
			},
			nodeAdmin(data) {
                this.checks = [];
				this.regioninfo = data
				this.nodeAdminShow = true
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.corp.node.regionlist/',
					data: {
						corp_id: data.corp_id,
						region_id: data.region_id
					}
				}).then((res) => {
					if (res.code == '200') {
						this.nodeList = res.data.map((v) => {
							if (v.is_able == 1) {
								this.checks.push(v.node_id)
							}
                            return {
								value: v.node_id,
								label: v.name
							}    
						})
						this.detailLoading = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			nodeAdminCancel() {
				this.nodeAdminShow = false
			},
			nodeAdminOk() {
				http({
					method: 'post',
					url: '/api.region.node.add/',
					data: {
						region_id: this.regioninfo.region_id,
						idlist: this.checks.join(',')
					}
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('设置区域管理站点成功');
						this.nodeAdminShow = false
					} else {
						this.$message.error(res.message);
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}

	.roleee.tes {
		width: 95%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.taes {
			display: flex;
			align-items: stretch !important;

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				display: flex;
				align-items: center;
			}
			.contet {
                padding: 20px;
			}
		}
	}
</style>