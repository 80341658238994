<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input size="default" v-model="name" placeholder="输入设备名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input size="default" v-model="number" placeholder="输入设备编号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="日期查询">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" style="width:240px;">
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<div>
				<a-button type="primary" icon="plus-circle" @click="dcDayClick">
					当日数据导出
				</a-button>
				<a-button type="primary" icon="plus-circle" @click="dcClick" style="margin-left: 15px;">
					导出
				</a-button>
			</div>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="type" slot-scope="text, record">
					<a-tag color="blue" v-if="record.type == 1">
						手动日结
					</a-tag>
					<a-tag color="pink" v-if="record.type == 2">
						自动日结
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="detail(record.acc_id)">
						详情
					</span>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal title="日结详情" :visible="visible" width="1580px" :confirm-loading="confirmLoading" :footer="null"
			@cancel="() => {visible = false}">
			<a-table :columns="columns1" :pagination="false" :data-source="detailData.list" bordered>

			</a-table>
			
			<div class="table-pagination">
				<a-pagination v-model="tablePage1" @change="tabelPageChange1" show-quick-jumper :total="tableTotal1"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'

	const columns = [{
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		}, {
			title: '设备编号',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: '金额',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '日结账号',
			dataIndex: 'admin_name',
			key: 'admin_name',
		}, 
		{
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		},
		{
			title: '类型',
			key: 'type',
			dataIndex: 'type',
			scopedSlots: {
				customRender: 'type'
			},
		},
		 {
			title: '结算账号',
			dataIndex: 'corp_account',
			key: 'corp_account',
		}, 
		{
			title: '结算银行',
			key: 'corp_bank',
			dataIndex: 'corp_bank',
		},{
			title: '交易批次号',
			dataIndex: 'trade_no',
			key: 'trade_no',
		},
		{
			title: '时间',
			dataIndex: 'add_time',
			key: 'add_time',
		},


		{
			title: '操作',
			key: 'age6',
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	const columns1 = [{
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '设备编号',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: '所属客户',
			key: 'corp_name',
			dataIndex: 'corp_name',
			width: '120px'
		},

		{
			title: '网点名称',
			dataIndex: 'node_name',
			key: 'node_name',
		},
		{
			title: '用户名',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: '真实姓名',
			key: 'real_name',
			dataIndex: 'real_name',
		},
		{
			title: '存款类型',
			dataIndex: 'detype_name',
			key: 'detype_name',
		},
		{
			title: '流水号',
			dataIndex: 'trade_no',
			key: 'trade_no',
		},
		{
			title: '日结批次号',
			key: 'acc_trade_no',
			dataIndex: 'acc_trade_no',
		},

		{
			title: '纸币存款金额',
			dataIndex: 'cash_amount',
			key: 'cash_amount',
			width: '120px'
		},
		{
			title: '信封存款金额',
			dataIndex: 'envelope_amount',
			key: 'envelope_amount',
			width: '120px'
		},
		{
			title: '总金额',
			key: 'amount',
			dataIndex: 'amount',
			width: '120px'
		},
		{
			title: '操作时间',
			key: 'add_time',
			dataIndex: 'add_time',
		},
	];
	export default {
		data() {
			return {
				columns,
				corp_id: null,
				name: '',
				number: '',
				start_date:'',
				end_date:'',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],

				visible: false,
				columns1,
				detailData: {},
				
				tablePage1: 1,
				tableTotal1: 0,
				detailId: null,
				confirmLoading:true,
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			disabledDate (current) {
			  return current && current > moment().endOf('day')
			},
			onChange(data) {
				this.start_date = moment(data[0]).format('YYYY-MM-DD')
				this.end_date = moment(data[1]).format('YYYY-MM-DD')
			},
			handleSubmit(e) {
			},
			corpChange(data) {
				this.corp_id = data
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			tabelPageChange1(page) {
				this.tablePage1 = page
				this.detail()
			},
			detail(id) {
				if (id) {
					this.detailId = id
					this.tablePage1 = 1
				}
				http({
					method: 'post',
					url: '/api.deposit.accdetail/',
					data: {
						acc_id: this.detailId,
						page: this.tablePage1
					}
				}).then((res) => {
					this.detailData = res.data
					this.visible = true
					this.tableTotal1 = res.data.total_count
				})
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.name = ''
					this.number = ''
					this.start_date = ''
					this.end_date = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.account/',
					data: {
						page: this.tablePage,
						name: this.name,
						number: this.number,
						start_date :this.start_date,
						end_date : this.end_date,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			dcClick () {
				http({
					method: 'post',
					url: '/api.account.datarecord.export/',
					data: {
						name: this.name,
						number: this.number,
						corp_id: this.corp_id,
						start_date :this.start_date,
						end_date : this.end_date,
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						this.$message.error(res.message);
					}
				})
			},
			dcDayClick () {
				http({
					method: 'post',
					url: '/api.account.daydata.export/',
					data: {
						name: this.name,
						number: this.number,
						corp_id: this.corp_id,
					}
				}).then((res) => {
					if (res.code == '200') {
						window.open(res.data)
					} else {
						this.$message.error(res.message);
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
